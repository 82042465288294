
    import { defineComponent } from 'vue';

    import { login } from './service/authService';

    export default defineComponent({
        setup() {
            return {
                login,
            };
        },
    });
